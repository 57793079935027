import React from "react"
import { Link, graphql } from "gatsby"
import _ from "lodash"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"
import TagList from "../components/tag-list"
import { rhythm, scale } from "../utils/typography"


class Tag extends React.Component {
    render() {
        const { data } = this.props
        const { title } = data.site.siteMetadata
        const tagCount = data.allMdx.group

        // sort tag count accordingly to the number its appear
        const sortedTagCount = _.sortBy(tagCount, ["totalCount", "fieldValue"])

        return (
            <Layout location={this.props.location} title={title}>
                <SEO title="All tags" />
                <div style={{
                    ...scale(-1 / 5),
                    display: `block`,
                    marginBottom: rhythm(1),
                    marginTop: rhythm(2),
                }}>
                    <TagList tags={_.map(sortedTagCount, "fieldValue")} />
                </div>
                <Link to="/blog">
                    <Button marginTop="85px">Go to blog</Button>
                </Link>
            </Layout>
        )
    }
}

export default Tag

export const pageQuery = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
    allMdx {
      group(field: frontmatter___tags, limit: 100) {
        fieldValue
        totalCount
      }
    }
  }
`
